<template>
    <div>
        <!-- Navigation Bar-->
        <navbar></navbar>
        <!-- Navigation ends -->
        <div class="wrapper">
            <div>
                <about></about>
                <!-- Footer -->
                <foot/>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from "../components/core/Navbar";
    import About from "../components/home/About";

    export default {
        name: "AboutUs",
        components: {
            About, 
            Foot: () => import(/* webpackPrefetch: true */ '../components/core/Footer'),
            Navbar},
        created(){
            this.$store.dispatch('coupon/googleVerification')
        }    
    }
</script>

<style scoped>

</style>