<template>
  <div class="media" @click="redirectToStore">
    <div class="media-left media-middle col-lg-4 pointer" >
      <img
        v-if="card.store_id.image"
        :src="$base_url + card.store_id.image"
        class="img-thumbnail"
        alt
      />
      <img
        v-else
        class="img-responsive"
        src="../../assets/placeholder.png"
        alt
      />
    </div>
    <div class="media-body">
      <h4 class="media-heading pointer">{{ card.title || "-" }}</h4>
      <p>{{ description(card) || "-" }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card"],
  methods: {
    redirectToStore() {
      this.$router.push({
        name: "SearchStore",
        params: { code: this.card.store_id._id , slug: this.card.store_id.slug, couponId:this.card._id },
      });
    },
    description(data) {
            if (data.hasOwnProperty('description')) {
                return data.description.length > 244 ? data.description.slice(0, 45) + '...' : data.description || "-"
            }
            return "..."
        }
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>