import { render, staticRenderFns } from "./PrivacyPolicyContent.vue?vue&type=template&id=b870c026&scoped=true&"
import script from "./PrivacyPolicyContent.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicyContent.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicyContent.vue?vue&type=style&index=0&id=b870c026&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b870c026",
  null
  
)

export default component.exports