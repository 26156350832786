<template>
    <div>

        <div class="breadcrmb-wrap hidden-xs">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active"><a href="/about">About</a></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!--end:Breadcrumbs -->
        <section class="results m-t-30">

            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="widget trending-coupons">
                            <div class="widget-body pp-body">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <p STYLE="margin-bottom: 0.11in"><B>Online
                                            Shopping Global Scenario</B></p>
                                        <p STYLE="margin-bottom: 0.11in">You’ve
                                            probably already heard about someone who is using cheap airlines and
                                            visits new countries for peanuts now and then. Or your friend may
                                            have bought a dress you always dreamed of for such a low price that
                                            you just can’t believe she was telling the truth. Your family
                                            member furnished their kitchen from top to bottom for half of the
                                            money you would think kitchen equipment would cost. Add to it free
                                            delivery and free professional assembly.</p>
                                        <p STYLE="margin-top: 0.19in; margin-bottom: 0.19in; ">
                                            <B>About
                                                Us</B></p>
                                        <p STYLE="margin-top: 0.19in; margin-bottom: 0.19in; ">
                                            Bisharafak
                                                is always the first website to get coupon codes from all of your
                                                favorite stores. We are the most extensive database for discount
                                                codes in the KSA and the most used. We have a simple and effective
                                                user interface that allows for natural movement across the site,
                                                regardless of a person's level of competence with technologies. Our
                                                goal is to make things as time-efficient and as straightforward as
                                                possible. We have the best deals
                                                on&nbsp;travel,&nbsp;activities,&nbsp;fashion,&nbsp;food&nbsp;and so
                                                much more. To put it quite simply, Bisharafak is different from other
                                                voucher code websites because we are bigger and better. We hope to
                                                give our customers the best deals and superb customer service.&nbsp;</p>
                                        <p STYLE="margin-bottom: 0.11in">We
                                            are a team who helps you in making all your online purchases the most
                                            affordable one. We make sure that all our stores are updated with all
                                            the latest coupons, deals and offers, so that any of our user finds
                                            the most apt coupon or offer for the product they are planning to
                                            buy, to get them at the lowest discounted price.</p>
                                        <p STYLE="margin-top: 0.19in; margin-bottom: 0.19in; ">
                                            In
                                                the current era, people choose to shop online most often because they
                                                can review, compare and buy favourite items in discounts from stores
                                                that could be delivered to doorstep instead of spending hours at
                                                shopping malls. Undoubtedly, online shopping is at a great pace of
                                                change that is highly influencing consumer’s buying behavior from
                                                traditional approach to everything online.</p>
                                        <p STYLE="margin-bottom: 0.11in">Online
                                            shoppers from countries US,UK, UAE, KSA, Egypt, Kuwait, Qatar and
                                            others can experience a huge wave of excellent promo codes from
                                            1000's of stores listed here. Getting ready to make the most out of
                                            Black Friday? Go on, we are with you.</p>
                                        <p STYLE="margin-bottom: 0.11in"><br><br>
                                        </p>
                                        <p STYLE="margin-bottom: 0.16in; background: #ffffff; ">
                                            <B>Types
                                                of Online Discounts Offered</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            &nbsp;<B>Cashback</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            Cashback
                                                offers are one of the most popular types of Online discounts. Pay
                                                using a specific method and get cashback with your order. It's
                                                transparent and guaranteed. Today, you can get cashback with any sort
                                                of payment that you make, and it really is a good method of saving,
                                                since you get the cash refunded to your account.</p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            &nbsp;<B>Student
                                                offer</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            This
                                                is an offered method that's more new and upcoming. Student offers are
                                                those offered to college students for their purchases. You only need
                                                to verify your student ID online to be able to avail these discounts.
                                                At present, only a few sites offer the discount, but it's expected to
                                                expand. It is a great advantage for people who may not be earning
                                                yet.</p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            &nbsp;<B>Corporate
                                                discounts</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            An
                                                advantage for all employees, corporate discounts are those offered to
                                                employees of corporates. We can avail the perks of extra discounts
                                                offered by our employer. The corporate's ID card will have to be
                                                verified in the store's account to be able to access the deal.</p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            &nbsp;<B>Bank
                                                offers/coupons</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            Have
                                                an account with a specific bank? Then you can avail offers by paying
                                                through their credit or debit cards. A discount will be applied to
                                                your total bill if you pay with that bank's card. We can also merge
                                                this with other deals, making it a bigger form of savings.</p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            &nbsp;<B>Travel
                                                Deals</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            Planning
                                                a last-minute trip? We have coupons just for you. Book with certain
                                                travel agents and avail these coupons. These coupons also can be
                                                applied depending on the place you're visiting, or with which resort
                                                you're booking. Some companies also give you the option to accumulate
                                                travel points, which we can then spend on your next trip.</p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff; ">
                                            &nbsp;<B>Share
                                                your codes</B></p>
                                        <p STYLE="margin-bottom: 0.1in; background: #ffffff;">
                                            Perhaps
                                                one of the most popular types of discounts, it is widely increasing.
                                                Share an app with your specific code, and get offers for it. It is a
                                                great deal for existing customers, and it also allows the company to
                                                increase its customer base, thus a win-win.</p>
                                        <p ><br>
                                        </p>
                                        <p ALIGN=JUSTIFY STYLE="margin-top: 0.19in; margin-bottom: 0.19in; ">
                                           Moving
                                                on to the other major features, comes the frequency of the updates.
                                                Now, you would not want to have a coupon code which does not work
                                                because it is expired, right? Bisharafak database is updated
                                                regularly on a daily and weekly basis to make sure that the users do
                                                not get disheartened with expired coupon codes. We have also made
                                                sure that the users get the most out of the website with a user
                                                friendly interface. Accept it or not, the interface of any webpage is
                                                a key point of its popularity which is very well understood by
                                                Bisharafak. All the sections are properly highlighted and the coupon
                                                codes and offers are properly aligned for the users to choose. There
                                                is also an option for the people to search their required coupon
                                                codes which they can browse in particular. All these features
                                                collectively make Bisharafak a perfect website for all the online
                                                shopping enthusiasts out there.</p>
                                        <p STYLE="margin-bottom: 0.11in"><br><br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end: Widget -->
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

    /* On screens that are 992px wide or less, go from four columns to two columns */
    @media screen and (min-width: 900px) {
        .pp-body {
            padding: 37px 100px;
        }
    }

    /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 899px) {
        .pp-body {
            padding: 10px 20px;
        }
    }
</style>