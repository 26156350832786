<template>
    <div>
        <!-- Navigation Bar-->
        <navbar></navbar>
        <!-- Navigation ends -->
        <div class="wrapper">
            <div>
                <PrivacyPolicyContent></PrivacyPolicyContent>
                <!-- Footer -->
                <foot/>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from "../components/core/Navbar";
    import Foot from "../components/core/Footer";
    import Card from "../components/home/Card";
    import TopStore from "../components/home/TopStore";
    import CarouselTabs from "../components/home/CarouselTabs";

    import CouponOfferModal from "../components/common/CouponOfferModal";
    import {commonCopyMixin} from "../components/Mixins/commonCopyMixin";
    import PrivacyPolicyContent from "../components/home/PrivacyPolicyContent";

    export default {
        name: "AboutUs",
        mixins: [commonCopyMixin],
        components: {PrivacyPolicyContent, CouponOfferModal, Foot, Navbar, Card, TopStore, CarouselTabs},
        created(){
            this.$store.dispatch('coupon/googleVerification')
        }
    }
</script>

<style scoped>

</style>