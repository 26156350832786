<template>
  <div>
    <!-- Navigation Bar-->
    <navbar></navbar>
    <!-- Navigation ends -->
    <div class="breadcrmb-wrap hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Contact</a></li>
              <li class="breadcrumb-item active">Contact page</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!--end:Breadcrumbs -->
    <section class="contact m-t-30">
      <div class="container">
        <div class="row">
          <!-- REGISTER -->
          <div class="col-md-8">
            <div class="widget">
              <div class="widget-body" ref="toolbarChat">
                <div class="alert alert-success" v-if="successMessage">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <strong
                    >Thank you for contacting us. We will get back to you in 1-2
                    business days</strong
                  >
                </div>
                <form class="form-horizontal" @submit.prevent="onSubmitContact">
                  <fieldset>
                    <div class="form-group">
                      <label class="col-md-3 control-label"
                        >You are a <sup>*</sup>
                      </label>
                      <div class="col-md-7">
                        <div class="radio radio-success radio-single">
                          <input
                            type="radio"
                            id="singleRadio1"
                            value="business"
                            name="radioSingle1"
                            v-model="you_are"
                            aria-label="Single radio One"
                          />
                          <label>Business</label>
                        </div>
                        <div class="radio radio-success radio-single">
                          <input
                            type="radio"
                            id="singleRadio1"
                            value="individual"
                            name="radioSingle1"
                            v-model="you_are"
                            checked="checked"
                            aria-label="Single radio Two"
                          />
                          <label>Individual</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-3 control-label"
                        >First Name <sup>*</sup>
                      </label>
                      <div class="col-md-7">
                        <input
                          name="name"
                          placeholder=""
                          class="form-control input-md"
                          type="text"
                          v-model="firstName"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-3 control-label"
                        >Last Name <sup>*</sup>
                      </label>
                      <div class="col-md-7">
                        <input
                          name="textinput"
                          placeholder=""
                          class="form-control input-md"
                          type="text"
                          v-model="lastName"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputEmail3" class="col-md-3 control-label"
                        >Email <sup>*</sup>
                      </label>
                      <div class="col-md-7">
                        <input
                          type="email"
                          class="form-control"
                          id="inputEmail3"
                          placeholder=""
                          v-model="email"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-3 control-label">Message</label>
                      <div class="col-md-7">
                        <textarea
                          class="form-control"
                          cols="40"
                          id="Description"
                          name="Description"
                          rows="10"
                          v-model="message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-3 control-label"></label>
                      <div class="col-md-7">
                        <div class="termbox mb10">
                          <div class="radio radio-success radio-single">
                            <input
                              type="radio"
                              id="singleRadio3"
                              value="option3"
                              name="radioSingle2"
                              aria-label="Single radio One"
                            />
                            <label
                              >I have read and agree to the
                              <a href="#">Terms &amp; Conditions</a>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group m-top-30">
                      <div class="col-md-3"></div>
                      <div class="col-md-7">
                        <div class="clearfix"></div>

                        <button
                          type="submit"
                          class="btn btn-danger btn-raised legitRipple"
                        >
                          Send message
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <!-- end: Widget -->
          </div>
          <!-- /REGISTER -->
          <!-- WHY? -->
          <div class="col-md-4">
            <!-- /widget -->
            <div class="widget trending-coupons">
              <!-- /widget heading -->
              <div class="widget-heading">
                <h3 class="widget-title text-dark">Trending Coupons</h3>
                <div class="clearfix"></div>
              </div>
              <div class="widget-body">
                <random
                  v-for="(card, i) in randomCoupon"
                  :key="i"
                  :card="card"
                />
              </div>
              <!-- // widget body -->
            </div>
          </div>
          <!-- /WHY? -->
        </div>
      </div>
    </section>
    <section class="newsletter-alert"></section>
    <!-- end:Newsletter signup -->
    <!-- Footer -->
    <foot />
    <!-- start modal -->
  </div>
</template>

<script>
import Navbar from "../components/core/Navbar";
import Foot from "../components/core/Footer";
import Random from "../components/contact/Random";
import { mapState } from "vuex";

export default {
  data() {
    return {
      successMessage: false,
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      you_are: "individual",
    };
  },
  components: { Foot, Navbar, Random },
  computed: {
    ...mapState({
      randomCoupon: (state) =>
        state.coupon.popularCoupon ? state.coupon.popularCoupon : [],
    }),
  },
  created() {
    this.$store.dispatch("coupon/fetchPopularCoupon").then((res) => {});
    this.$store.dispatch('coupon/googleVerification')
  },
  methods: {
    onSubmitContact() {
      this.successMessage = true;
      (this.firstName = ""), (this.lastName = "");
      this.email = "";
      this.message = "";
      window.scrollTo({
          top: 100,
          behavior: "smooth"
        });
    },

  },
};
</script>