<template>
  <a class="col-sm-4 tab url">
    <div class="media">
      <div
        class="media-left media-middle col-sm-4 pointer"
        @click="redirectToStore"
      >
        <img v-if="card.store_id.image" :src="$base_url + card.store_id.image" class="img-thumbnail" alt />
        <img v-else class="img-responsive" src="../../assets/placeholder.png" alt />
      </div>
      <div class="media-body">
        <h4 class="media-heading">{{ card.title }}</h4>
        <p>{{ card.store_id.name }}</p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: ["card"],
  methods: {
    redirectToStore() {
      this.$router.push({
        name: "SearchStore",
        params: { code: this.card.store_id.id,slug: this.card.store_id.slug  },
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>