<template>
    <div>
        <!-- Navigation Bar-->
        <navbar></navbar>
        <!-- Navigation ends -->
        <div class="wrapper">
            <div>
                <NotFound></NotFound>
                <!-- Footer -->
                <foot/>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from "../components/core/Navbar";
    import Foot from "../components/core/Footer";
    export default {
        name: "AboutUs",
        components: {Foot,
        Foot: () => import(/* webpackPrefetch: true */ '../components/core/Footer'),
        NotFound: () => import(/* webpackPrefetch: true */ '../components/common/NotFound.vue'),
        Navbar
        },
        mounted(){
            this.$store.dispatch('coupon/googleVerification')
        }
    }
</script>

<style scoped>
#error {
  margin: 0 auto;
  display: block;
}
</style>