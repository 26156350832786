<template>
  <!-- start modal -->
  <!-- Large modal -->

  <div
    class="coupon_modal modal fade"
    tabindex="-1"
    role="dialog"
    id="couponOfferModal"
  >
    <div
      class="modal-dialog modal-md"
      role="document"
      :style="isMobile() ? 'height: 95vh!important;' : ''"
    >
      <div
        class="modal-content"
        :style="isMobile() ? 'height: 95vh!important;' : ''"
      >
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="ti-close"></i>
          </span>
        </button>

        <div class="coupon_modal_content">
          <div class="row">
            <div class="coupon-data col-sm-4 text-center">
              <div class="savings text-center">
                <div>
                  <a :href="storeLink" target="_blank">
                    <img
                      v-if="displayStoreImage"
                      :src="
                        'https://api.bisharafak.com/api/' + displayStoreImage
                      "
                      class="img-thumbnail"
                      alt
                    />
                    <img
                      v-else
                      class="img-thumbnail"
                      src="../../assets/placeholder.png"
                      alt
                    />
                    <div class="type">{{ store_name }}</div>
                  </a>
                </div>
              </div>
              <br />
              <div class="savings text-center">
                <div v-if="description">
                  <div class="type" v-html="description.slice(0, 170)"></div>
                </div>
                <div v-else>
                  <div class="type "></div>
                </div>
              </div>
              <!-- end:Savings -->
            </div>
            <div class="col-sm-8 text-center">
              <div class="alerts-border coupon-title">
                <h2>{{ title }}</h2>
              </div>

              <div class=" row">
                <div class="col-sm-12">
                  <h5 class="text-center text-uppercase m-t-20 text-muted">
                    {{ $t("copyExclusiveCoupon") }}
                  </h5>

                  <div
                    class="col-sm-12 col-xs-12 text-center blink_me"
                    style="padding-right:0!important;"
                  >
                    <a
                      href="javascript://"
                      class="coupon_code alert alert-info "
                      @click.stop.prevent="copyTestingCode"
                      :title="code"
                    >
                      <span class="" style="color:#f30;">
                        <span class="coupon_icon">
                          <i class="ti-cut"></i>
                        </span>
                        {{ code }}
                      </span>
                    </a>
                    <input type="hidden" id="testing-code" :value="code" />
                  </div>
                  <!-- v-if="this.$store.state.couponCopyed==0" -->
                  <div
                    v-if="this.$store.state.couponCopyed == 0"
                    class="col-sm-12 col-xs-12"
                    style="margin-top:15px; margin-bottom:25px;"
                  >
                    <a
                      href="javascript://"
                      class=" alert alert-info "
                      style="
                                        padding:10px!important; 
                                        background-image: linear-gradient(to bottom,#808080 0,#808080 100%)!important;"
                      @click="copyTestingCode()"
                      :title="$t('copyCouponCode')"
                    >
                      <span class="" style="color:white;">
                        <span class="coupon_icon">
                          <i class="ti-layers"></i>
                        </span>
                        {{ $t("copyCouponCode") }}
                      </span>
                    </a>
                  </div>
                  <div
                    class="col-sm-12 col-xs-12 "
                    v-if="$store.state.redictCoupon"
                  >
                    <small
                      v-if="this.$store.state.rtl_mode == 0"
                      class="text-danger"
                      style="margin-top:20px!important;"
                    >
                      {{ $t("redirectingIn5Sec1") }}
                      {{ $store.state.redirectCounter }}
                      {{ $t("redirectingIn5Sec2") }}
                    </small>
                    <small
                      v-if="this.$store.state.rtl_mode == 1"
                      class="text-danger"
                      style="margin-top:20px!important;"
                    >
                      {{ $t("redirectingToStore") }}
                    </small>
                  </div>
                  <!-- <div class="col-sm-12 col-xs-12">
                    <p>{{description||''}}</p>
                  </div> -->
                </div>
              </div>

              <div
                v-show="alert"
                class="row coupon_modal_content"
                style="padding:0; marign-top:5px;"
              >
                <div class="col-8">
                  <div
                    v-if="$store.state.redictCoupon > 0"
                    class="alert alert-success"
                    role="alert"
                  >
                    Coupon Code Copied Successfully!
                  </div>
                  <div
                    v-if="
                      $store.state.redictCoupon == 0 &&
                        this.$store.state.rtl_mode == 0
                    "
                    class="alert alert-success"
                    role="alert"
                  >
                    Coupon code has been copied. Redirecting you to the store.
                  </div>
                  <div
                    v-if="
                      $store.state.redictCoupon == 0 &&
                        this.$store.state.rtl_mode == 1
                    "
                    class="alert alert-success"
                    role="alert"
                  >
                    {{ $t("redirectingToStore") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end: Coupon modal content -->
      </div>
    </div>
  </div>
  <!-- end: Modall -->
</template>

<script>
export default {
  name: "ModalLarge",
  props: ["offerCoupon", "eachCouponOffer", "storeCouponOffer", "storeImage"],
  data() {
    return {
      amount: null,
      title: "",
      description: null,
      code: "ABC2020",
      navigate: null,
      store_name: null,
      image: null,
      alert: true,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    displayStoreImage() {
      if (this.storeImage) {
        return this.storeImage.image;
      }
      return this.image;
    },
    storeLink() {
      if (this.storeImage) {
        return this.storeImage.domain_link;
      }
      return "#";
    },
  },
  watch: {
    offerCoupon: function(offer) {
      if (offer) {
        // console.log("offer", offer);
        this.amount = offer.amount;
        this.title = offer.title;
        this.description = offer.description;
        this.code = offer.code;
        this.navigate = offer.navigate;
        this.image = offer.store_id.image;
        this.store_name = offer.store_name;
      }
    },

    eachCouponOffer: function(eachoffer) {
      if (eachoffer) {
        let store = eachoffer.store_id;

        this.amount = eachoffer.amount;
        this.title = eachoffer.title;
        this.description = eachoffer.description;
        this.navigate = eachoffer.link ?? null;
        this.image = store.image ?? null;
        this.store_name = store.name;

        if (eachoffer.coupon_code) {
          this.code = eachoffer.coupon_code;
        } else {
          this.code = "ABC2020";
        }
      }
    },

    storeCouponOffer: function(storeoffer) {
      if (storeoffer) {
        // console.log("storeoffer", storeoffer);
        // console.log("storeImage", this.storeImage);
        this.title = storeoffer.title;
        this.description = storeoffer.description;
        if (storeoffer.coupon_code) {
          this.code = storeoffer.coupon_code;
        } else {
          this.code = "ABC2020";
        }
        this.navigate = storeoffer.link;
        this.image = this.storeImage.image;
        this.store_name = this.storeImage.name;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    setTimeout(() => (this.alert = false), 2500);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    hideAlert() {
      setTimeout(function() {
        this.alert = 0;
      }, 1000);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    copyTestingCode() {
      this.alert = true;
      setTimeout(() => (this.alert = false), 2500);
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        //alert("Code was copied " + msg);
        //window.location.href = root.offerCoupon.navigate;

        var root = this;
        console.log("root.navigate", root.navigate);
        setTimeout(function() {
          window.location.href = root.navigate;
        }, 100);
      } catch (err) {
        //alert('Oops, unable to copy');
      }
      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.blink_me {
  animation: blinker 1s linear infinite;
  color: #f30;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.alerts-border {
  border: 1px #f30 dashed;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  img.img-thumbnail {
    width: 50%;
  }

  .coupon-title {
    margin-bottom: 15px;
  }
}
</style>
