<template>
<div class="coupon-wrapper coupon-single" :id="singlecoupon._id">
    <div class="row">
        <div class="ribbon-wrapper hidden-xs">
            <div class="ribbon">Featured</div>
        </div>
        <div class="coupon-data col-sm-2 text-center">
            <div class="savings text-center">
                <div>
                    <img v-if="storeProfileSearch.image" :src="$base_url + storeProfileSearch.image" class="img-thumbnail" alt />
                    <img v-else class="img-thumbnail" src="../../assets/placeholder.png" alt />
                    <div class="type">{{ storeProfileSearch.name }}</div>
                </div>
            </div>
            <!-- end:Savings -->
        </div>

        <!-- end:Coupon data -->
        <div class="coupon-contain col-sm-7">
            <!--  <ul class="list-inline list-unstyled">-->
            <!--     <li class="sale label label-pink">Sale</li>-->
            <!--          &lt;!&ndash; <li class="popular label label-success">98% success</li> &ndash;&gt;-->
            <!--          &lt;!&ndash; <li>-->
            <!--            <span class="verified text-success">-->
            <!--              <i class="ti-face-smile"></i>Verified-->
            <!--            </span>-->
            <!--          </li> &ndash;&gt;-->
            <!--          <li>-->
            <!--            <span class="used-count">78 used</span>-->
            <!--     </li>-->
            <!--  </ul>-->
            <h4 class="coupon-title">
                <a href="javascript://">{{ singlecoupon.title }}</a>
            </h4>
            <p data-toggle="collapse" data-target="#more">
                {{ description(singlecoupon) }}
            </p>
            <p id="more" class="collapse">
                Don't miss out on all the coupon savings.Get you coupon now and save
                big
            </p>
            <!-- <ul class="coupon-details list-inline">
         <li class="list-inline-item">
            <div class="btn-group" role="group" aria-label="...">
             <button
               type="button"
               class="btn btn-default btn-xs"
               data-toggle="tooltip"
               data-placement="left"
               title
               data-original-title="It worked"
             >
               <i class="ti-thumb-up"></i>
             </button>
             <button
               type="button"
               class="btn btn-default btn-xs"
               data-toggle="tooltip"
               data-placement="top"
               title
               data-original-title="It didn't work"
             >
               <i class="ti-thumb-down"></i>
             </button>
           </div> 
         </li>-->
            <!-- <li class="list-inline-item">30% of 54 recommend</li>
         <li class="list-inline-item">
           <a href="#">Share</a>
       </li> -->
            <!-- </ul> -->
            <!-- end:Coupon details -->
        </div>
        <!-- end:Coupon cont -->

        <div class="button-contain col-sm-3 text-center">
            <p class="btn-code" data-toggle="modal" data-target=".couponModal" @click="openGetCode">
                <span class="partial-code">BTSBAGS</span>
                <span class="btn-hover">Get Code</span>
            </p>
            <div class="btn-group" role="group" aria-label="...">
                <!-- <button type="button" class="btn btn-default btn-xs">
            <i class="ti-share"></i>
          </button> -->
                <!-- <button type="button" class="btn btn-default btn-xs">
            <i class="ti-email"></i>
          </button>
          <button type="button" class="btn btn-default btn-xs">
            <i class="ti-mobile"></i>
          </button> -->
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 10px">
        <div style="border-bottom: 1px dashed rgb(229, 229, 229);"></div>
        <div>
            <div style="display: flex; flex-wrap: wrap;">
                <div style="display: flex; justify-content: flex-start; align-items: center; margin: 3px 20px;">
                    <!-- <div style="margin-right: 10px; font-weight: 600; color: gray;"> Expiry : Invalid Date</div> -->
                    <div class="copyTextwithBorder" @click="showCopped(singlecoupon._id)">
                        Share Offer</div>
                    <div class=" copy-text" id="copy-text" v-if="showCtext==true">
                        Copied
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //row -->
</div>
</template>

<script>
import coupon from '../../store/modules/coupon';
export default {
    props: ["singlecoupon", 
    "storeProfileSearch", 
    "automaticCouponModelTriggerId",
    "currentRedirectionMode",
    "currentRedirectionURL"],
    data() {
        return {
            showCtext: false,
            currentURL: this.$router.currentRoute.path,
            baseURL: window.location.origin,
        }
    },
    created() {
        //it will open modal for a coupon if ?couponId will match
        if (this.automaticCouponModelTriggerId == this.singlecoupon._id) {
            this.openGetCode()
        }
    },
    methods: {
        openGetCode() {
            this.$emit('click-get-code', this.singlecoupon, this.storeProfileSearch);
        },
        description(data) {
            if (data.hasOwnProperty('description')) {
                // Check if the description is HTML
                const isHTML = /<[a-z][\s\S]*>/i.test(data.description);
                if (isHTML) {
                    // If it's HTML, remove HTML tags and return only text content
                    const cleanDescription = data.description.replace(/<[^>]+>/g, '');
                    return cleanDescription.length > 244 ? cleanDescription.slice(0, 244) + '...' : cleanDescription || "-";
                } else {
                    // If it's plain text, truncate if necessary
                    return data.description.length > 244 ? data.description.slice(0, 244) + '...' : data.description || "-";
                }
            }
            return "...";
        },
        showCopped(couponId) {
            let url = this.baseURL + this.currentURL + '?couponId=' + couponId
            if(this.currentRedirectionMode.includes('zrwor') 
            || this.currentRedirectionMode.includes('zrwr')){
             url = this.baseURL + this.currentURL + '/'+ couponId + '/' + this.currentRedirectionMode;
            //  url = this.baseURL + this.currentURL + '/'+ couponId + '/' + this.currentRedirectionMode + `?r=${this.currentRedirectionURL}`;
            }

            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(url);
                this.showCtext = true;
                setTimeout(() => {
                    this.showCtext = false;
                }, 1000);
                return Promise.reject('The Clipboard API is not available.');
            }
        },
    },
};
</script>

<style scoped>
.copy-text {
    left: 699px;
    top: 198px;
    color: darkgreen;
    border: 1px dashed;
    padding: 3px;
    border-radius: 5px;
}

.copyTextwithBorder {
    font-size: 10px;
    width: 70px;
    border: 1px dashed;
    border-radius: 5px;
    padding: 5px 3px;
    font-weight: 700;
    cursor: pointer;
    margin-right: 10px;
    color: gray;
}
</style>
