<template>
<!-- Navigation Bar-->
<header class="header" id="header" :style="{'background-color':headerColor}">
    <div class="top-nav navbar m-b-0 b-0" :style="{'background-color':headerColor}">
        <div class="container">
            <div class="row text-center">
                <!-- LOGO -->
                <div class="text-left col-md-12 col-sm-9 col-xs-9">
                    <router-link to="/" class="logo">
                        <img class="img-responsive " :class="headerLogoCss" style="" :src="headerLogo" alt="" />
                    </router-link>
                </div>
                <div class="text-right col-sm-3 col-xs-3">
                    <div class="menu-item" @click="toggleNavbar()">
                        <a class="navbar-toggle" style="height: 0; color: white;" type="button" data-toggle="collapse" data-target="#navigation">
                            <div class="lines" id="menuToggle" style="margin: 10px auto 17px!important;">
                                <span></span> <span></span><span></span>
                            </div>
                        </a>
                        <!-- End mobile menu toggle-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-custom shadow" style="float: unset!important;">
        <div class="container">
            <div id="navigation" v-bind:class="{ 'show': show }">
                <!-- Navigation Menu-->
                <ul class="navigation-menu text-center" style="float: unset!important;">
                    <li>
                        <router-link to="/"><i class="ti-home"></i> <span> Home </span></router-link>
                    </li>
                    <li class="has-submenu">
                        <router-link to="/stores">
                            <i class="ti-agenda"></i> <span> Stores </span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/coupon"><i class="ti-cut"></i> <span> Coupons </span></router-link>
                    </li>
                    <li>
                        <router-link to="/privacy-policy"><i class="ti-receipt"></i> <span> PrivacyPolicy </span></router-link>
                    </li>
                    <li>
                        <router-link to="/contact"><i class="ti-email"></i> <span> Contact </span></router-link>
                    </li>
                    <li>
                        <router-link to="/about"><i class="ti-layout-list-thumb"></i> <span> About </span></router-link>
                    </li>
                </ul>
                <!-- End navigation menu  -->
            </div>

        </div>
    </div>
</header>
</template>

<script>
import { mapState } from "vuex"
export default {
    name: "Navbar",
    data() {
        return {
            show: false,
        }
    },
    computed: {
        ...mapState({
            footerData: (state) => state.coupon.footer.footerConfig,
            header: (state) =>
                state.coupon.header ? state.coupon.header : 0,
        }),
        headerColor(){
            if(this.footerData){
                return this.footerData.hasOwnProperty('header_color')? this.footerData.header_color:'#25282e'
            }
            return '#25282e'
        },
        headerLogo() {
            var defaultLogo = '@/assets/images/logo/logo-new.png';
            var url = 'https://api.bisharafak.com/api/';
            if(this.footerData) {
                return this.footerData.hasOwnProperty('logo')? url + this.footerData.logo : defaultLogo
            }
            return defaultLogo
        },
        headerLogoCss() {
            if(this.footerData) {
                return this.footerData.hasOwnProperty('logo')? 'logoHeight' : 'logo-image'
            }
            return 'logo-image'
        }
    },
    methods: {
        toggleNavbar() {
            this.show = !this.show
        },
        async getHeaders() {
            await this.$store
                .dispatch("coupon/fetchHeaders")
                .then((res) => {})
        }
    },
    created() {
        this.getHeaders()
    }
};
</script>

<style scoped>
/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (min-width: 910px) {
    .logo-image {
        width: 60%;
        object-fit: cover;
        height: 50px;
    }
 
    .logoHeight{
        height: 50px;
        object-fit:contain;
    }
}

@media screen and (max-width: 909px) {
    .logo-image {
        width: 100%;
        object-fit: contain;
        height: 40px;
    }

    .logoHeight{
        height: 40px;
        object-fit:contain;
    }
}
</style>
