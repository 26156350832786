<template>
<div class="coupon-wrapper row">

    <div class="coupon-data col-sm-2 text-center">
        <div class="savings text-center">
            <div class="pointer" @click="redirectToStore()">
                <img v-if="carddetail.store_id.image" :src="$base_url + carddetail.store_id.image" class="img-thumbnail" alt />
                <img v-else class="img-responsive" src="../../assets/placeholder.png" alt />
                <!-- <div class="large">%</div>
          <div class="small">off</div> -->
                <div class="type">{{ carddetail.store_id.name }}</div>
            </div>
        </div>

        <!-- end:Savings -->
    </div>
    <!-- end:Coupon data -->
    <div class="coupon-contain col-sm-7">
        <!--      <ul class="list-inline list-unstyled">-->
        <!--        <li class="sale label label-pink">Sale</li>-->

        <!--        <li>-->
        <!--          <span class="used-count">27 used</span>-->
        <!--        </li>-->
        <!--      </ul>-->
        <h4 class="coupon-title">
            <a href="javascript://" @click="redirectToStore()">
                {{ carddetail.title || "-"}}
            </a>
        </h4>
        <p data-toggle="collapse">
            {{description(carddetail)}}
        </p>
    </div>
    <!-- end:Coupon cont -->
    <div class="button-contain col-sm-3 text-center">
        <p class="btn-code" data-toggle="modal" data-target=".couponModal" @click="openGetCode">
            <span class="partial-code">BTSBAGS</span>
            <span class="btn-hover">Get Code</span>
        </p>

    </div>
</div>
</template>

<script>
export default {
    props: ["carddetail"],
    methods: {
        openGetCode() {
            this.$emit("click-get-code", this.carddetail);
        },
        redirectToStore() {
            this.$router.push({
                name: 'SearchStore',
                params: {
                    code: this.carddetail.store_id._id,
                    slug: this.carddetail.store_id.slug
                },
            })
        },
        description(carddetail) {
            if (carddetail.hasOwnProperty('description')) {
                return carddetail.description.length > 244 ? carddetail.description.slice(0, 244) + '...' : carddetail.description || "-"
            }
            return "..."
        }
    }
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
