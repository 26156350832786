<template>
<div>
    <!-- Navigation Bar-->
    <navbar></navbar>
    <!-- Navigation ends -->
    <div class="breadcrmb-wrap hidden-xs">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active"><a href="#">Coupons</a></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!--end:Breadcrumbs -->
    <section class="results m-t-30">
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <!-- // tags -->
                    <div class="widget">
                        <!-- /widget heading -->
                        <div class="widget-heading">
                            <h3 class="widget-title text-dark">Top Stores</h3>
                            <div class="clearfix"></div>
                        </div>
                        <div class="widget-body">
                            <div class="row">
                                <display-stores-horizontally 
                                v-for="(top, i) in topStores" 
                                :key="i" 
                                :store="top"/>
                                <!-- /thumb -->
                            </div>
                        </div>
                    </div>

                    <!--/search form -->
                    <div class="widget trending-coupons">
                        <!-- /widget heading -->
                        <div class="widget-heading">
                            <h3 class="widget-title text-dark">Trending Coupons</h3>
                            <div class="clearfix"></div>
                        </div>
                        <div class="widget-body">
                            <random v-for="(card, i) in popularCouponData" :key="i" :card="card" />
                        </div>
                        <!-- // widget body -->
                    </div>
                </div>
                <!--/col -->
                <div class="col-sm-9">
                    <!-- end: Widget 
                    -->
                <!-- <div v-if="popularCouponData.length>0">
                    <single-coupon v-for="(singlecoupon, i) in popularCouponData" 
                    :key="i" :carddetail="singlecoupon" 
                    :featured="i%2===0?1:0" 
                    :automaticCouponModelTriggerId="automaticCouponModelTriggerId" @click-get-code="clickGetCode" />
                </div> -->
                   
                    <CouponCard 
                    v-for="(carddetail, i) in popularCouponData" 
                    :key="i" :carddetail="carddetail" 
                    :featured="i%2===0?1:0" 
                    @click-get-code="clickGetCode"
                    ></CouponCard>
                </div>
            </div>
        </div>
    </section>
    <coupon-offer-modal :eachCouponOffer="eachCouponOffer">
    </coupon-offer-modal>
    <!-- end: Modall -->
</div>
</template>

<script>
import Navbar from "../components/core/Navbar";
import Foot from "../components/core/Footer";
import SingleCoupon from "@/components/store/SingleCoupon";
import {
    mapState
} from "vuex";
import Random from "../components/contact/Random";
import Card from "../components/home/Card";
import CouponCard from "../components/common/CouponCard";
import CouponOfferModal from "../components/common/CouponOfferModal";
import {
    commonCopyMixin
} from "../components/Mixins/commonCopyMixin";
import DisplayStoresHorizontally from "../components/contact/DisplayStoresHorizontally";
import api2 from "../utils/api2";

export default {
    name: "Coupon",
    components: {
        CouponCard,
        Foot,
        Navbar,
        Random,
        DisplayStoresHorizontally,
        Card,
        CouponOfferModal,
        SingleCoupon
    },
    mixins: [commonCopyMixin],
    data() {
        return {
            eachCouponOffer: [],
            window: {
                width: 0,
                height: 0
            },
            automaticCouponModelTriggerId: "",
        };
    },
    computed: {
        ...mapState({
            randomCoupon: (state) =>
                state.coupon.popularCoupon ? state.coupon.popularCoupon : [],
            topStores: (state) =>
                state.coupon.topStores ? state.coupon.topStores : [],
        }),
        popularCouponData() {
            let coupons = this.$store.state.coupon.popularCoupon;
            return coupons.filter(val => val.is_visible === true)
        }
    },
    methods: {
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        async clickGetCode(value) {
            // console.log(value);
            if (value.coupon_code) {
                this.textToCopy = value.coupon_code;
            } else {
                this.textToCopy = "ABC2020";
            }


            // let url = "https://api.bisharafak.com/v2/fetch/couponurl"
            //  await api2.post(url, {
            //         coupon_id: value._id,
            //         resolution: this.window.width + 'x' + this.window.height,
            //         lpURL: document.URL,
            //     }).then((response) => {
            //         if(response.data){
            //             let data = response.data
            //             if(data.success = true){
            //                 value.link = data.link
            //             }
            //         }
            // })
            value.link = value.finalLink;

            this.eachCouponOffer = value;
            this.$store.state.couponCopyed = 1;

            this.commonCopy(value.finalLink);
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    async created()  {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        await this.$store.dispatch("coupon/fetchPopularCoupon").then((res) => {});
        await this.$store.dispatch("coupon/fetchTopStores").then((res) => {});

        if (this.$route.query.couponId) {
            this.automaticCouponModelTriggerId = this.$route.query.couponId;
        }
        this.$store.dispatch('coupon/googleVerification')
    },
};
</script>
